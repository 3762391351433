
import { defineComponent } from "vue-demi";
import { mapGetters } from 'vuex';
import { useAPI } from "@/use";
import { Cart } from '@/interfaces/cart/cart.dto';

import CartContainer from './modules/Container.vue';
import Preloader from '@/components/common/preloader/index.vue';
import { Order } from "@/interfaces/order/order.dto";

export default defineComponent({
  name: 'MainCartMain',
  created() {
    this.getCartMethod();
  },
  data() {
    return {
      cart: null as Cart.Dto | null,
      loading: false,
    }
  },
  mounted() {
    this.onEmits();
  },
  unmounted() {
    this.offEmits();
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    async getCartMethod() {
      this.loading = false;
      const result = await useAPI().cart.getMethod();
      this.cart = result.data;
      if (this.user && this.cart) {
        this.cart.phone = this.user.phone;
        this.cart.name = this.user.name;
      }
      this.loading = true;
    },
    createOrder({ order, payment }: { order: Order.Dto, payment: { payment: string, payment_url: string } }) {
      this.cart = null;
      setTimeout(() => {
        if (!order.user) return this.unRegisteredOrder();
        if (order.amount > 0) return this.registeredOrder(payment);
      }, 400);
    },
    unRegisteredOrder() {
      this.notification('Оплатить заказ может только авторизованный пользователь! Авторизуйтесь или зарегистрируйтесь на сайте для оплаты заказа!', 'success');
      setTimeout(() => {
        this.$router.push('/auth');
      }, 6000);
    },
    registeredOrder(payment: { payment: string, payment_url: string }) {
      this.notification('Через несколько секунд Вы будете перенаправлены на оплату заказа!', 'success');
      setTimeout(() => {
        location.replace(payment.payment_url);
      }, 6000);
    },
    notification(message: string, status: string) {
      return this.$store.commit('createNotification', {
        message, status
      });
    },
    // Emitter
    onEmits() {
      this.emitter.on('continueCart', this.continueCartEmitter);
    },
    offEmits() {
      this.emitter.off('continueCart', this.continueCartEmitter);
    },
    continueCartEmitter(cart: Cart.Dto) {
      this.cart = cart;
    }
  },
  components: {
    CartContainer,
    Preloader
  }
})
