import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b120b0f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "additional" }
const _hoisted_2 = { class: "codes flex-center-between" }
const _hoisted_3 = { class: "alsb fz12" }
const _hoisted_4 = ["placeholder", "onUpdate:modelValue"]
const _hoisted_5 = ["placeholder", "onUpdate:modelValue"]
const _hoisted_6 = {
  key: 0,
  class: "btn btn--main btn--small btn--disabled"
}
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.additional, (input, key) => {
        return (_openBlock(), _createElementBlock("div", { key: key }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(input.name), 1),
          (input.applied)
            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 0,
                placeholder: input.placeholder,
                type: "text",
                "onUpdate:modelValue": ($event: any) => ((input.value) = $event),
                disabled: ""
              }, null, 8, _hoisted_4)), [
                [_vModelText, input.value]
              ])
            : _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 1,
                placeholder: input.placeholder,
                type: "text",
                "onUpdate:modelValue": ($event: any) => ((input.value) = $event)
              }, null, 8, _hoisted_5)), [
                [_vModelText, input.value]
              ]),
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              (input.applied)
                ? (_openBlock(), _createElementBlock("button", _hoisted_6, "Применен"))
                : (input.value)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "btn btn--main btn--small",
                      onClick: ($event: any) => (_ctx.submit(key, input.value))
                    }, "Применить", 8, _hoisted_7))
                  : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024)
        ]))
      }), 128))
    ])
  ]))
}