import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ebc0bd6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product flex items-center" }
const _hoisted_2 = { class: "product--selected" }
const _hoisted_3 = { class: "product--container flex items-center" }
const _hoisted_4 = { class: "image" }
const _hoisted_5 = {
  key: 0,
  alt: "Product Image"
}
const _hoisted_6 = { class: "info flex items-end justify-between" }
const _hoisted_7 = { class: "info--left" }
const _hoisted_8 = { class: "name als" }
const _hoisted_9 = { class: "inc flex items-center" }
const _hoisted_10 = { class: "inc--quantity leska_title fz22" }
const _hoisted_11 = { class: "info--right leksa" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_empty_image_icon = _resolveComponent("empty-image-icon")!
  const _component_minus_icon = _resolveComponent("minus-icon")!
  const _component_plus_icon = _resolveComponent("plus-icon")!
  const _component_cancel_icon = _resolveComponent("cancel-icon")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.product.selected) = $event)),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('toggleSelectProduct')))
      }, null, 544), [
        [_vModelCheckbox, _ctx.product.selected]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.product.product.image && _ctx.product.product.image[0])
          ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_5, null, 512)), [
              [_directive_lazy, _ctx.product.product.image[0].src]
            ])
          : (_openBlock(), _createBlock(_component_empty_image_icon, { key: 1 }))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.product.product.name) + " (" + _toDisplayString(_ctx.currentWeight) + ")", 1),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", {
              class: "inc--block flex-center cursor",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleProductCart(-1)))
            }, [
              _createVNode(_component_minus_icon)
            ]),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.product.quantity), 1),
            _createElementVNode("div", {
              class: "inc--block flex-center cursor",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleProductCart(1)))
            }, [
              _createVNode(_component_plus_icon)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("span", null, _toDisplayString(_ctx.currentPrice * _ctx.product.quantity) + " ₽", 1)
        ])
      ]),
      _createElementVNode("div", {
        class: "delete cursor",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('deleteProduct')))
      }, [
        _createVNode(_component_cancel_icon)
      ])
    ])
  ]))
}