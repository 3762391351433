
import { defineComponent, PropType } from "vue-demi";
import { Cart } from "@/interfaces/cart/cart.dto";
import { PRODUCT_TYPES } from "@/utils/enums";

export default defineComponent({
  emits: ['createOrder'],
  name: "CartOrder",
  props: {
    cart: {
      type: Object as PropType<Cart.Dto>,
      required: true
    },
    pending: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    delivery() {
      return Number(this.cart?.delivery?.price) || 0;
    },
    discount() {
      return this.cart.promocode ? this.total * 0.1 : 0;
    },
    certificate() {
      return this.cart.certificate ? this.cart.certificate.price : 0;
    },
    amount() {
      const amount = (this.total + this.delivery) - this.discount - this.certificate;
      return amount < 0 ? 0 : amount;
    },
    total() {
      return this.cart.products.filter(p => p.selected).reduce((acc, p) => {
        const amount = p.product.type === PRODUCT_TYPES.UNIT ? p.product.pricePerUnit : p.product.pricePerPack;
        return acc + (amount * p.quantity);
      }, 0);
    }
  }
});
