
import { defineComponent, PropType } from "vue-demi";
import { mask } from 'vue-the-mask';
import { Cart } from "@/interfaces/cart/cart.dto";
import { useAPI } from "@/use";

import Empty from "./ContainerEmpty.vue";
import Product from './Product.vue';
import Order from './Order.vue';
import Additional from './Additional.vue';
import { PRODUCT_TYPES } from "@/utils/enums";

export default defineComponent({
  emits: ['createOrder'],
  name: "CartContainer",
  directives: {
    mask
  },
  props: {
    cart: {
      type: Object as PropType<Cart.Dto>,
    },
  },
  data() {
    return {
      pending: false,
    }
  },
  computed: {
    isThereProducts() {
      return this.cart && this.cart?.products?.length;
    },
    currentDeliveryName() {
      return this.cart?.delivery ? 'Доставка рассчитана' : 'Рассчитать доставку'
    },
    currentDeliveryPrice() {
      return this.cart?.delivery?.price || 0;
    },
    certificate() {
      if (!this.cart) return 0;
      return this.cart.certificate ? this.cart.certificate.price : 0;
    },
    discount() {
      return this.cart?.promocode ? this.total * 0.1 : 0;
    },
    total() {
      if (!this.cart) return 0;
      return this.cart.products.filter(p => p.selected).reduce((acc, p) => {
        const amount = p.product.type === PRODUCT_TYPES.UNIT ? p.product.pricePerUnit : p.product.pricePerPack;
        return acc + (amount * p.quantity);
      }, 0);
    }
  },
  methods: {
    async toggleProductCart(product: string, inc: number) {
      if (!this.cart) return;
      const result = await useAPI().cart.toggleProductMethod(product, inc);
      this.cart.products = result.data.products;
      if (this.cart.delivery) {
        this.cart.delivery = null;
        this.notification('Необходимо заново рассчитать доставку!', 'info');
      }
      this.setProductLength(this.cart.products);
    },
    async toggleSelectProduct(product: Cart.Product) {
      if (!this.cart) return;
      await useAPI().cart.toggleSelectProductMethod(product.product._id, product.selected);
      if (this.cart.delivery) {
        this.cart.delivery = null;
        this.notification('Необходимо заново рассчитать доставку!', 'info');
      }
    },
    async deleteProduct(product: Cart.Product) {
      if (!this.cart) return;
      const result = await useAPI().cart.deleteProductCart(product.product._id);
      this.cart.products = this.cart.products.filter(p => p.product._id !== result.data);
      if (this.cart.delivery) {
        this.cart.delivery = null;
        this.notification('Необходимо заново рассчитать доставку!', 'info');
      }
      this.setProductLength(this.cart.products);
    },
    async updateCartComment() {
      if (!this.cart) return;
      await useAPI().cart.updateCommentMethod({ comment: this.cart.comment, phone: this.cart.phone });
    },
    async clearCart() {
      if (!this.cart) return;
      await useAPI().cart.deleteCart();
      this.cart.products = [];
      this.setProductLength([]);
    },
    async createOrder() {
      if (!this.cart?.delivery) return this.notification('Необходимо рассчитать доставку!', 'error');
      if (!this.cart?.name) return this.notification('Необходимо указать корректный ФИО!', 'error');
      if (!this.cart?.phone) return this.notification('Необходимо указать номер телефона!', 'error');
      
      this.pending = true;
      const result = await useAPI().order.createMethod({ name: this.cart?.name, phone: this.cart?.phone });
      this.$emit('createOrder', result.data);
      this.pending = false;
    },
    setProductLength(products: Array<{ quantity: number }>) {
      const length = products.reduce((acc, p) => acc + p.quantity, 0);
      this.$store.commit('setProductLength', length);
    },
    openDelivery() {
      this.$store.commit('createDelivery', this.cart?._id);
    },
    notification(message: string, status: string) {
      return this.$store.commit('createNotification', {
        message, status
      });
    }
  },
  components: {
    Empty,
    Product,
    Order,
    Additional,
  },
});
