
import { defineComponent, PropType } from "vue-demi";
import { Cart } from "@/interfaces/cart/cart.dto";
import { CART_ADDITIONAL_INPUTS } from '@/store/common/cart';
import { useAPI } from "@/use";

export default defineComponent({
  name: "CartAdditional",
  props: {
    cart: {
      type: Object as PropType<Cart.Dto>,
      required: true,
    },
  },
  data() {
    return {
      additional: CART_ADDITIONAL_INPUTS,
    };
  },
  created() {
    this.checkPromocode();
    this.checkCertificate();
  },
  methods: {
    async submit(id: 'promocode' | 'certificate') {
      const value = this.additional[id].value;
      if (id === 'promocode') {
        const result = await useAPI().cart.applyPromocodeMethod(value);
        this.cart.promocode = result.data;
        this.checkPromocode();
      }
      if (id === 'certificate') {
        const result = await useAPI().cart.applyCertificateMethod(value);
        this.cart.certificate = result.data;
        this.checkCertificate();
      }
    },
    checkPromocode() {
      if (this.cart.promocode) {
        this.additional.promocode.value = this.cart.promocode.code;
        this.additional.promocode.applied = true;
      }
    },
    checkCertificate() {
      if (this.cart.certificate) {
        this.additional.certificate.value = this.cart.certificate.code;
        this.additional.certificate.applied = true;
      }
    },
  },
});
