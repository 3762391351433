
import { defineComponent } from "vue-demi";
import CartMain from "@/components/main/cart/index.vue";

export default defineComponent({
  name: "CartView",
  components: {
    CartMain,
  },
});
