
import { defineComponent, PropType } from "vue-demi";
import { Cart } from "@/interfaces/cart/cart.dto";
import { EmptyImageIcon, PlusIcon, MinusIcon, CancelIcon } from '@/plugins/icons';
import { PRODUCT_TYPES } from "@/utils/enums";

export default defineComponent({
  emits: ['toggleProductCart', 'toggleSelectProduct', 'deleteProduct'],
  name: 'CartProduct',
  props: {
    product: {
      type: Object as PropType<Cart.Product>,
      required: true
    },
  },
  methods: {
    toggleProductCart(inc: number) {
      this.$emit('toggleProductCart', this.product.product._id, inc);
    }
  },
  computed: {
    currentPrice() {
      return this.product.product.type === PRODUCT_TYPES.UNIT ? this.product.product.pricePerUnit : this.product.product.pricePerPack;
    },
    currentWeight() {
      const weight = (this.product.product.weight * this.currentLength) * this.product.quantity;
      return weight >= 1000 ? `${weight / 1000} кг` : `${weight} г`;
    },
    currentLength() {
      return this.product.product.type === PRODUCT_TYPES.UNIT ? 1 : this.product.product.unitsPerPack;
    }
  },
  components: {
    EmptyImageIcon, PlusIcon, MinusIcon, CancelIcon
  }
})
