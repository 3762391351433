export const CART_ADDITIONAL_INPUTS = {
  promocode: {
    name: "Промокод",
    applied: false,
    value: "",
    placeholder: "Введите промокод",
  },
  certificate: {
    name: "Подарочная карта",
    applied: false,
    value: "",
    placeholder: "Введите подарочную карту",
  },
};

export const CART_USER_INPUTS = {
  inputs: [
    { show: true, id: 'name', name: 'ФИО', placeholder: 'Введите ФИО', type: 'text' },
    { show: true, id: 'email', name: 'Email', placeholder: 'Введите Email', type: 'text' },
    { show: true, id: 'password', name: 'Пароль', placeholder: 'Введите пароль', type: 'text' },
  ],
  data: {
    email: '',
    name: '',
    password: ''
  }
}